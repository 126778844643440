<div class="main-banner item-bg-one">
    <div class="creative-banner-three"></div>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-text">
                    <h4>We Are Creative</h4>
                    <h1>We Are
                        <ngx-typed-js [strings]="['Professional', 'Passionate', 'Creative', 'Designer', 'Unique', 'Best', 'Support']" [typeSpeed]="100" [loop]="true" [backSpeed]="100" [showCursor]="false">
                            <span class="typing"></span>
                        </ngx-typed-js>
                    </h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <a (click)="onClick('about')" class="btn btn-primary">Get Started</a>

                </div>
            </div>
        </div>
    </div>
</div>

<app-welcome></app-welcome>

<app-about></app-about>



<app-cta></app-cta>





<app-why-we-different></app-why-we-different>







<app-feedback></app-feedback>



<app-contact></app-contact>
