<div id="about" class="about-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Mission</span></h2>
            <p>Discover a world where technology meets excellence. Join us on a transformative journey and let’s create a future that’s efficient, intelligent, and sustainable. Dive in and explore the limitless possibilities with STS!</p>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <h2>We Are Digital <span>Architects</span></h2>
                        <p>Tailor solutions that align perfectly with our client's unique requirements and visions. </p>
                        <p>Uphold the highest standards of quality and reliability in every project we undertake.</p>
                    </div>
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i>Creative Design</li>
                        <li><i class="fas fa-check"></i>Customization</li>
                        <li><i class="fas fa-check"></i>Mapping cinematography</li>
                        <li><i class="fas fa-check"></i>Quality Assurance</li>
                        <li><i class="fas fa-check"></i>Continuous Innovation</li>
                        <li><i class="fas fa-check"></i>Client-Centric Approach</li>
                    </ul>
                    <p>Exceed client expectations through impeccable service, transparent communication, and a flexible approach, ensuring maximum satisfaction.</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-video">
                    <img src="assets/img/cloud drones v3.png" alt="about">
                    <div class="video-btn">
                        <!-- <button class="popup-youtube" (click)="ngxSmartModalService.getModal('popupOne').open()"><i class="fas fa-play"></i></button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="video-popup">
    <!-- <ngx-smart-modal #popupOne [identifier]="'popupOne'">
        <iframe src="https://www.youtube.com/embed/WQ119jAN4Xo" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </ngx-smart-modal> -->
</div>
