<div id="welcome" class="welcome-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Vision</span></h2>
            <p>To be a global leader in delivering exceptional tech solutions, we specialize in software development, testing, and integrating advanced systems with a keen emphasis on the Internet of Things (IoT). Constantly pushing technological boundaries, we are dedicated to fostering a culture of innovation, integrity, and excellence, bridging traditional systems and the digital future. </p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="far fa-edit"></i>
                    </div>
                    <h3>SOFTWARE</h3>
                    <p>Software development isn't just about writing code—it's about building intuitive solutions. Our development approach combines the latest technological innovations with practical functionality, leading to products that don't just impress, but also deliver optimal user experiences.</p>
                    <a (click)="onClick('about')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-laptop"></i>
                    </div>
                    <h3>TESTING</h3>
                    <p>Quality Assurance is not just a step but our ethos, where we blend industry-leading tools and expertise to set, not just meet, the highest software standards, ensuring resilience and excellence.</p>
                    <a (click)="onClick('why-we-here')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="far fa-life-ring"></i>
                    </div>
                    <h3>SOLUTIONS</h3>
                    <p>Our system integration solutions are designed to facilitate smooth interoperability between disparate systems, ensuring a cohesive and efficient workflow. By bridging the gaps between various platforms and applications, we empower businesses to operate at peak performance and unlock new potentials</p>
                    <a (click)="onClick('why-we-here')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
        </div>
    </div>
    <div class="shape2"><img src="assets/img/shape1.png" alt="image"></div>
</div>
