<div class="cta-area ptb-100">
    <div class="container">
        <div class="cta-content">

            <div class="section-title mb-0">
                <h2>Get The Best For Your Business</h2>
                <p>In today's competitive market, settling for generic solutions can limit your business potential. At STS, we believe in offering exclusive services tailored to fit the unique needs of each client. Our commitment to excellence and bespoke solutions ensures that your business stands out, operates efficiently, and achieves its objectives. Dive deep with STS, where we craft strategies not just to meet, but to exceed your expectations, driving unparalleled growth and success for your enterprise.</p>
                <a (click)="onClick('contact')" class="btn btn-primary">Contact Us</a>

            </div>
        </div>
    </div>
</div>
