<div class="main-banner item-bg-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-text">
                    <h4>We are creative</h4>
                    <h1><span>Diverse</span> Experience</h1>
                    <p> Pioneering the Future of Software and Technology Solutions!</p>
                    <a (click)="onClick('about')" class="btn btn-primary">Get Started</a>

                </div>
            </div>
        </div>
    </div>
</div>

<app-welcome></app-welcome>

<app-about></app-about>



<app-cta></app-cta>



<app-why-we-different></app-why-we-different>







<app-feedback></app-feedback>



<app-contact></app-contact>
