<div id="contact" class="contact-area ptb-100 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h4>Get in Touch</h4>
            <h2>Let's <span>Contact</span> Us</h2>
            <p>Reach out to us anytime! Use the 'Let's Contact Us' form below to send your inquiries, feedback, or
                suggestions. We value your input and promise to get back to you swiftly.</p>
        </div>
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="contact-form">
                    <div *ngIf="showMessage" [classList]="success ? 'message success-message' : 'message error-message'">
                        {{ messageText }}
                    </div>
                    <form [formGroup]="form" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label for="name">Name</label>
                                    <input
                                        required
                                        minlength="3"
                                        maxlength="50"
                                        name="name"
                                        type="text"
                                        class="form-control"
                                        id="name"
                                        formControlName="name">
                                    <div *ngIf="form.get('name').touched && !form.get('name').valid"
                                         class="alert alert-danger">
                                        <div *ngIf="form.hasError('required', 'name')">Name is required.</div>
                                        <div *ngIf="form.get('name').errors?.['maxlength']">Name must be no more
                                            than {{ form.get('name').errors?.['maxlength']?.requiredLength }}characters.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label for="email">Email</label>
                                    <input
                                        name="email"
                                        type="email"
                                        class="form-control"
                                        id="email"
                                        formControlName="email">
                                    <div *ngIf="form.get('email').touched && !form.get('email').valid"
                                         class="alert alert-danger">
                                        <div *ngIf="form.get('email').errors?.['required']">Email Address is required.
                                        </div>
                                        <div *ngIf="form.get('email').errors?.['email']">Please enter a valid email
                                            address.
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Subject</label>
                                    <input name="subject"
                                           type="text"
                                           class="form-control"
                                           id="subject"
                                           formControlName="subject">
                                    <div class="alert alert-danger"
                                         *ngIf="form.get('subject').touched && !form.get('subject').valid">Subject is
                                        required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Phone Number</label>
                                    <input name="number"
                                           type="text"
                                           class="form-control" id="number"
                                           formControlName="number">
                                    <div class="alert alert-danger"
                                         *ngIf="form.get('number').touched && !form.get('number').valid">
                                        Phone number is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <label>Message</label>
                                    <textarea name="message"
                                              id="message" cols="30"
                                              rows="4"
                                              class="form-control"
                                              formControlName="message"></textarea>
                                    <div class="alert alert-danger"
                                         *ngIf="form.get('message').touched && !form.get('message').valid">Message is
                                        required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit"
                                        class="btn btn-primary"
                                        >Send Message
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="contact-info">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <ul>
                                <li><i class="fas fa-map-marker-alt newcolor"></i> <span>Location:</span>Beverly Hills
                                    CA 90210
                                </li>
                                <li><i class="far fa-envelope"></i> <a
                                    href="mailto:kari.dominguez@softwaretestsolutions.net"><span>Email:</span>kari.dominguez@softwaretestsolutions.net</a>
                                </li>
                                <li><i class="fas fa-phone-alt"></i> <a href="tel:+12023163169"><span>Call:</span>+1(202)-316-31-69</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
