<div class="why-we-different ptb-100 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h2>Why We <span>Are Different</span></h2>
            <p>STS stands out in the tech realm. Our dedication extends beyond software to pioneering drone applications. Where others see limits, we see opportunities for innovation, integrity, and unmatched excellence. With STS, you're not just using technology; you're shaping the future.</p>
        </div>
        <div id="why-we-here" class="tabset why-we-different-tabset">
            <!-- Tab 1 -->
            <input type="radio" name="tabset" id="tab1" aria-controls="speed-flexibility" checked>
            <label for="tab1">Speed and flexibility</label>
            <!-- Tab 2 -->
            <input type="radio" name="tabset" id="tab2" aria-controls="profeesional-work">
            <label for="tab2">Profeesional work</label>
            <!-- Tab 4 -->
            <input type="radio" name="tabset" id="tab3" aria-controls="custom-support">
            <label for="tab3">Custom Support</label>
            <input type="radio" name="tabset" id="tab4" aria-controls="qa">
            <label for="tab4">Quality Assurance</label>
            <div class="tab-panels">
                <div id="speed-flexibility" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/octopus_mutitasking.png" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>Speed and flexibility</h3>
                                <p>At STS, we prioritize swift execution without compromising on adaptability. Our processes are fine-tuned for rapid delivery, while our adaptable approach ensures solutions that align perfectly with your ever-evolving needs. Experience the blend of speed and flexibility that sets STS apart.</p>
                                <ul>
                                    <li><i class="fas fa-check"></i> Creative Design</li>
                                    <li><i class="fas fa-check"></i> Optimized Processes</li>
                                    <li><i class="fas fa-check"></i> Adaptive Frameworks</li>
                                    <li><i class="fas fa-check"></i> Skilled Team</li>
                                    <li><i class="fas fa-check"></i> Client-Centric Approach</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="profeesional-work" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/ai qa.png" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>Profeesional work</h3>
                                <p>At STS, we set the gold standard for professionalism in the tech realm. Our commitment to excellence is unwavering, and every project we undertake reflects the highest level of expertise, precision, and integrity.</p>
                                <ul>
                                    <li><i class="fas fa-check"></i> Expert Team</li>
                                    <li><i class="fas fa-check"></i> Quality Assurance</li>
                                    <li><i class="fas fa-check"></i> Ethical Standards</li>
                                    <li><i class="fas fa-check"></i> Structured Workflow</li>
                                    <li><i class="fas fa-check"></i> Feedback-Driven</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="custom-support" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/support v2.png" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>24/7 support</h3>
                                <p>At STS, we believe that exceptional support is not just an added service—it's a promise. No matter the hour or the day, our dedicated support team is always on standby, ensuring you receive timely assistance whenever you need it.</p>
                                <ul>
                                    <li><i class="fas fa-check"></i> Round-the-Clock Availability</li>
                                    <li><i class="fas fa-check"></i> Swift Response Time</li>
                                    <li><i class="fas fa-check"></i> Proactive Monitoring</li>
                                    <li><i class="fas fa-check"></i> Regular Updates</li>
                                    <li><i class="fas fa-check"></i> Feedback Loop</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="qa" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/ai solutions.png" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>Quality Assurance</h3>
                                <p>n the vast landscape of software development, Quality Assurance at STS isn't just a phase—it's embedded in our DNA. While many projects might view QA as a checkpoint, we perceive it as a continuous journey towards perfection. Our meticulous QA processes are fortified by industry-leading tools, skilled experts, and a relentless pursuit of excellence. We not only identify and rectify issues but also anticipate potential challenges, ensuring our software stands resilient in real-world scenarios. Dive into STS's realm, where Quality Assurance isn't just about meeting standards; it's about setting them, consistently elevating the benchmark higher than the rest.</p>
                                <ul>
                                    <li><i class="fas fa-check"></i> Industry-Leading Tools</li>
                                    <li><i class="fas fa-check"></i> Proactive Problem-Solving</li>
                                    <li><i class="fas fa-check"></i> Automated Testing</li>
                                    <li><i class="fas fa-check"></i> Continuous Integration (CI)</li>
                                    <li><i class="fas fa-check"></i> Real-World Scenarios</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
